//@ts-nocheck
import { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import Socials from "../components/utils/Socials";

import { ReactComponent as Scan } from "../assets/Scan.svg";
import { auth } from "../config/firebase";
import { signInAnonymously, signOut } from "firebase/auth";

import { useFormik } from "formik";
import * as yup from "yup";
import { Typography, Button, TextField, Box } from "@mui/material";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const Home = () => {
  const [isNotLost, setIsNotLost] = useState<boolean>(false);
  let navigate = useNavigate();
  const validationSchema = yup.object({
    code: yup
      .string()
      .matches(
        /^[a-zA-Z0-9]+$/g,
        "Code LNF doit contenir que des caractères et nombres"
      )
      .trim()
      .length(10, "Code LNF doit contenir exactement 10 caractères")
      //.label(t("screen.signup.email.label", "Email est"))
      .required("Code LNF est un champ obligatoire"),
  });
  useEffect(() => {
    signOut(auth).catch((error) => {
      console.error(error);
      // setDisabled(false);
    });

    return () => {};
  }, []);
  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (!auth?.currentUser) {
        await signInAnonymously(auth);
      }
      navigate(`/${values.code.toUpperCase()}`);
    },
  });
  const theme = createTheme({
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            backgroundColor: "#fff",
            "&.Mui-disabled": {
              backgroundColor: "#e4e4e4",
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginTop: 5,
            marginLeft: 0,
            fontWeight: 600,
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {isNotLost ? (
            <>
              <Typography variant="h5" component="h1" gutterBottom>
                Merci pour votre visite
              </Typography>
              <Typography my={5} variant="h6" gutterBottom>
                Cet objet n’est pas perdu ou n’existe pas.
              </Typography>
              <Box
                sx={{
                  marginY: 2,
                  height: 60,
                  width: 60,
                  borderRadius: "13px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  background:
                    "linear-gradient(180deg, #1BE8CF 8.5%, #2652C0 100%)",
                }}
                component="div"
              >
                <Scan />
              </Box>
              <Typography variant="subtitle2" gutterBottom component="div">
                LNFOUND
              </Typography>
              <Typography
                sx={{
                  marginY: 2,
                  fontWeight: "700",
                }}
                variant="subtitle1"
                gutterBottom
                component="h3"
              >
                Application LNFound
              </Typography>
              <Button
                style={{
                  fontWeight: "600",
                  color: "#2575C4",
                  height: 46,
                  fontSize: "17",
                  background: "#00FFE1",
                  boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
                  borderRadius: "35px",
                }}
                type="submit"
                onClick={() => setIsNotLost(false)}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Réessayer
              </Button>
            </>
          ) : (
            <>
              <Box
                sx={{
                  marginY: 2,
                  height: 60,
                  width: 60,
                  borderRadius: "13px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  background:
                    "linear-gradient(180deg, #1BE8CF 8.5%, #2652C0 100%)",
                }}
                component="div"
              >
                <Scan />
              </Box>
              <Typography sx={{ marginY: 2 }} variant="h6">
                Vous avez trouvé un objet?
              </Typography>

              <Box
                component="form"
                onSubmit={formik.handleSubmit}
                sx={{ mt: 2, width: "100%" }}
              >
                <Typography variant="subtitle2" component="div">
                  Entrez le code LNFOUND ci-dessous
                </Typography>
                <TextField
                  fullWidth
                  sx={{
                    input: {
                      borderRadius: 25,
                      textTransform: "uppercase",
                      backgroundColor: "white",
                    },
                  }}
                  margin="normal"
                  id="code"
                  inputProps={{
                    maxLength: 10,
                  }}
                  name="code"
                  value={formik.values.code}
                  placeholder="Ex. LNF6050LSD"
                  onChange={formik.handleChange}
                  error={formik.touched.code && Boolean(formik.errors.code)}
                  helperText={formik.touched.code && formik.errors.code}
                />

                <Button
                  style={{
                    fontWeight: "600",
                    color: "#2575C4",
                    height: 46,
                    fontSize: "17",
                    background: "#00FFE1",
                    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
                    borderRadius: "35px",
                  }}
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Envoyer
                </Button>
              </Box>
            </>
          )}

          <Typography
            sx={{
              typography: {
                xs: {
                  textAlign: "center",
                },
                lg: {
                  textAlign: "center",
                },
              },
            }}
            variant="body2"
            gutterBottom
          >
            Ou plus facile encore, téléchargez gratuitement l’application et
            converser directement avec le propriétaire
          </Typography>
          <Socials />
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Home;
