//@ts-nocheck
import { useState, useEffect } from "react";
import {
  isSignInWithEmailLink,
  signInWithEmailLink,
  updatePassword,
} from "firebase/auth";
import { useSearchParams } from "react-router-dom";

import { updateDoc, doc } from "firebase/firestore";
import { db, auth } from "../config/firebase";
import { useFormik } from "formik";
import * as yup from "yup";
import { Typography, Button, TextField, Box } from "@mui/material";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
const SignUp = () => {
  const [showMessage, setMessage] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    // Verify the user went through an email link and the saved email is not null
    if (isSignInWithEmailLink(auth, window.location.href)) {
      // Sign the user in
      signInWithEmailLink(
        auth,
        searchParams.get("email"),
        window.location.href
      ).catch((err) => {
        if (
          err?.code === "auth/invalid-action-code" ||
          err?.code === "auth/expired-action-code"
        ) {
          setMessage("Ce lien est expiré ou a déjà été utilisé");
        }
        console.log("error", err);
      });
    }
  }, []);
  const validationSchema = yup.object({
    password: yup
      .string()
      // .label(t("screen.signup.password.label", "Mot de passe"))
      .required("Mot de passe est un champ obligatoire"),

    pseudo: yup
      .string()
      // .label(t("screen.signup.pseudo.label", "Pseudo"))
      .required("Pseudo est un champ obligatoire"),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      pseudo: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await updatePassword(auth.currentUser, values.password);
        await updateDoc(doc(db, "users", `${auth.currentUser?.uid}`), {
          pseudo: values.pseudo,
        });
        setMessage("Votre inscription a été enregistrée");
      } catch (error) {
        console.log("error", error);
      }
    },
  });
  const theme = createTheme({
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            backgroundColor: "#fff",
            "&.Mui-disabled": {
              backgroundColor: "#e4e4e4",
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginTop: 5,
            marginLeft: 0,
            fontWeight: 600,
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {showMessage ? (
            <Typography variant="h5" component="h1" gutterBottom>
              {showMessage}
            </Typography>
          ) : (
            <>
              <Typography variant="h5" component="h1" gutterBottom>
                Inscription
              </Typography>

              <Box
                component="form"
                onSubmit={formik.handleSubmit}
                sx={{ mt: 2, width: "100%" }}
              >
                <TextField
                  fullWidth
                  sx={{
                    input: {
                      borderRadius: 25,
                      backgroundColor: "white",
                    },
                  }}
                  margin="normal"
                  id="pseudo"
                  name="pseudo"
                  value={formik.values.pseudo}
                  placeholder="Pseudo"
                  onChange={formik.handleChange}
                  error={formik.touched.pseudo && Boolean(formik.errors.pseudo)}
                  helperText={formik.touched.pseudo && formik.errors.pseudo}
                />
                <TextField
                  fullWidth
                  type="password"
                  sx={{
                    input: {
                      borderRadius: 25,
                      backgroundColor: "white",
                    },
                  }}
                  margin="normal"
                  id="password"
                  name="password"
                  value={formik.values.password}
                  placeholder="Mot de passe"
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />

                <Button
                  style={{
                    fontWeight: "600",
                    color: "#2575C4",
                    height: 46,
                    fontSize: "17",
                    background: "#00FFE1",
                    boxShadow: "0px 0px 6px rgba(0, 0, 0, 0.25)",
                    borderRadius: "35px",
                  }}
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Continuer
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default SignUp;
