import { CircularProgress } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../src/screens/Home";

// import routes from "./config/routes";
import useAuth from "./hooks/useAuth";
import Center from "./components/utils/Center";
import ObjectFound from "./screens/ObjectFound";
import Conversation from "./screens/Conversation";
import SignUp from "./screens/SignUp";
function App() {
  const { loading } = useAuth();

  if (!loading) {
    return (
      <Center>
        <CircularProgress
          sx={{
            color: "white",
          }}
        />
      </Center>
    );
  }
  return (
    <div>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route path="/conversation/:id" element={<Conversation />} />
          <Route path="/:code" element={<ObjectFound />} />
          <Route path="/" element={<Home />} />
          <Route path="/signup" element={<SignUp />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
