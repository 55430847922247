//@ts-nocheck

import { useEffect, useState } from "react";
import { auth } from "../config/firebase";

export default function useAuth() {
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      // console.log("user onAuthStateChanged", user);
      setUser(user);
      setLoading(true);
    });
  }, []);
  const isLoggedIn = () => loading && user !== null;

  return { user, loading, isLoggedIn };
}
